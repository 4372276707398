<template>
  <nav class="navbar navbar-expand-md navbar-light bg-light mb-4">
    <router-link
      v-if="pledge.campaign_id"
      class="btn btn-primary mr-2"
      :to="{ name: 'campaign', params: { id: pledge.campaign_id } }"
    >
      <font-awesome-icon icon="bullhorn" /> View Campaign
    </router-link>
    <router-link
      v-if="pledge.promotion_id"
      class="btn btn-primary mr-2"
      :to="{ name: 'promotion', params: { id: pledge.promotion_id } }"
    >
      <font-awesome-icon icon="clipboard-list" /> View Promotion
    </router-link>
    <button
      v-if="canByRole(['admin', 'developer'])"
      class="btn btn-secondary ml-auto"
      type="button"
      data-toggle="modal"
      data-target="#modal-pledge-edit"
    >
      <font-awesome-icon icon="edit" /> Edit
    </button>
    <button
      v-if="canByRole(['admin', 'developer'])"
      class="btn btn-danger ml-2"
      type="button"
      data-toggle="modal"
      data-target="#modal-pledge-delete"
    >
      <font-awesome-icon icon="trash" />
    </button>
  </nav>
</template>

<script>
export default {
  name: 'PledgeNavigation',
  props: {
    pledge: {
      type: Object,
      required: true,
    },
  },
};
</script>
