<template>
  <div class="container app-content pt-4">
    <Error v-if="errorCampaign" :error="errorCampaign" />
    <Error v-if="errorPromotion" :error="errorPromotion" />
    <Error v-if="error" :error="error" />
    <Loading v-else-if="isLoading || isLoadingCampaign || isLoadingPromotion" />
    <template v-else>
      <!-- Page Header -->
      <PageHeader :title="fullName" />

      <PledgeNavigation :pledge="pledge" />

      <div v-if="false" class="row">
        <div class="col-6" v-for="(value, name) in pledge" :key="`item-${name}`">
          <div class="row">
            <div class="col-6">
              <strong>{{ name }}</strong>
            </div>
            <div class="col-6">
              {{ value ? value : '--' }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-sm-6">
          <h5>Campaign</h5>
          <p>
            <template v-if="campaign.campaign_id">
              <router-link :to="{ name: 'campaign', params: { id: campaign.campaign_id } }">
                {{ campaign.title }}
              </router-link>
              (ID: {{ campaign.campaign_id }})
            </template>
            <template v-else>--</template>
          </p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Promotion</h5>
          <p>
            <template v-if="promotion.promotion_id">
              <router-link :to="{ name: 'promotion', params: { id: promotion.promotion_id } }">
                {{ promotion.title }}
              </router-link>
              (ID: {{ promotion.promotion_id }})
            </template>
            <template v-else>--</template>
          </p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Pledged At</h5>
          <p>{{ fullDateTime(pledge.promised_at) }}</p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Includable At</h5>
          <p>{{ fullDateTime(pledge.includable_at) }}</p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Pledge Amount</h5>
          <p>$ {{ formatNumber(pledge.amount) }}</p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Pledge Total</h5>
          <p>$ {{ formatNumber(pledge.total) }}</p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Pledge Recurrence</h5>
          <p>{{ occurrence }}</p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Pledge Interval</h5>
          <p>{{ interval }}</p>
        </div>
      </div>

      <h4>Contact Info</h4>
      <div class="row">
        <div class="col-12 col-sm-6">
          <h5>Email Address</h5>
          <p>{{ email }}</p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Phone Number</h5>
          <p>{{ phone }}</p>
        </div>

        <div class="col-12 col-sm-6">
          <h5>Mailing Address</h5>
          <address>
            {{ mailingAddress }}
          </address>
        </div>
      </div>
    </template>

    <Modal id="modal-pledge-edit">
      <template v-slot:title>Edit Pledge</template>

      <template v-slot:body>
        <PledgeForm
          v-if="!isLoading"
          :initial="pledge"
          modal="#modal-pledge-edit"
          @complete="fetchData"
        />
      </template>
    </Modal>

    <Modal id="modal-pledge-delete">
      <template v-slot:title>Delete Pledge</template>

      <template v-slot:body>
        <PledgeDeleteForm v-if="!isLoading" :id="pledge.pledge_id" modal="#modal-pledge-delete" />
      </template>
    </Modal>
  </div>
</template>

<script>
import * as _ from 'lodash';
import helpers from 'mixins/helpers';
import hasTimeDate from '../mixins/hasTimeDate';
import Error from 'components/Error';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import PageHeader from 'components/PageHeader';
import PledgeDeleteForm from 'components/forms/PledgeDeleteForm';
import PledgeForm from 'components/forms/PledgeForm';
import PledgeNavigation from 'components/pledges/PledgeNavigation';

export default {
  name: 'PledgesShow',
  mixins: [helpers, hasTimeDate],
  components: {
    Error,
    Loading,
    Modal,
    PageHeader,
    PledgeDeleteForm,
    PledgeForm,
    PledgeNavigation,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      errorCampaign: null,
      errorPromotion: null,
      isLoading: false,
      isLoadingCampaign: false,
      isLoadingPromotion: false,
    };
  },
  computed: {
    campaign() {
      return this.$store.getters['campaigns/getCurrentCampaign'];
    },
    interval() {
      return _.capitalize(this.pledge.interval);
    },
    occurrence() {
      if (this.pledge.occurrence === 1) {
        return 'Single';
      }

      return 'Recurring';
    },
    pledge() {
      return this.$store.getters['pledges/getCurrentPledge'];
    },
    promotion() {
      return this.$store.getters['promotions/getCurrentPromotion'];
    },
    email() {
      return this.pledge.email ?? '--';
    },
    phone() {
      return this.pledge.phone ?? '--';
    },
    fullName() {
      if (this.pledge.display_as) {
        return this.pledge.display_as;
      }

      return [this.pledge.first, this.pledge.last].join(' ');
    },
    mailingAddress() {
      const address = [];

      if (this.pledge.street) {
        address.push(this.pledge.street);
      }

      if (this.pledge.city && this.pledge.state && this.pledge.zip) {
        address.push(`${this.pledge.city}, ${this.pledge.state} ${this.pledge.zip}`);
      } else if (this.pledge.city && this.pledge.state) {
        address.push(`${this.pledge.city}, ${this.pledge.state}`);
      }

      if (address.length == 0) {
        return '--';
      }

      return address;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    getCountry(code) {
      return !code
        ? ''
        : this.countries
            .filter((country) => {
              return _.upperCase(country.code) === _.upperCase(code);
            })
            .shift().name;
    },
    fetchData() {
      // #TODO lock the page while loading
      this.error = null;
      this.errorCampaign = null;
      this.errorPromotion = null;
      this.isLoading = true;

      this.$store
        .dispatch('pledges/get', { id: this.id })
        .catch((error) => {
          this.error = this.$errorProcessor(error);
        })
        .then(() => {
          this.isLoading = false;

          // get Campaign data
          if (this.pledge.campaign_id) {
            this.isLoadingCampaign = true;

            this.$store
              .dispatch('campaigns/get', { id: this.pledge.campaign_id })
              .catch((error) => {
                this.errorCampaign = this.$errorProcessor(error);
              })
              .then(() => {
                this.isLoadingCampaign = false;
              });
          }

          // get Promotion data
          if (this.pledge.promotion_id) {
            this.isLoadingPromotion = true;

            this.$store
              .dispatch('promotions/get', { id: this.pledge.promotion_id })
              .catch((error) => {
                this.errorPromotion = this.$errorProcessor(error);
              })
              .then(() => {
                this.isLoadingPromotion = false;
              });
          }
        });
    },
    formatNumber(number) {
      return number ? number.toLocaleString() : '';
    },
  },
};
</script>

<style scoped lang="scss"></style>
